.drone {
    right: -2%;
    top: -3%;
    transform: scale(.8) rotate(0);
    animation: moveAround 7s linear 2s infinite forwards;
}

@keyframes moveAround {
    0% {
        right: -2%;
        top: -3%;
        transform: scale(.8) rotate(-30deg);
    }
    30% {
        right: 10%;
        top: -3%;
        transform: scale(.8) rotate(-30deg);
    }
    45% {
        right: 10%;
        top: 15%;
        transform: scale(.8) rotate(0);
    }
    60% {
        right: 5%;
        top: 15%;
        transform: scale(.8) rotate(0);
    }
    80% {
        right: -2%;
        top: 15%;
        transform: scale(.8) rotate(0);
    }
    100% {
        right: -2%;
        top: -3%;
        transform: scale(.8) rotate(-30deg);
    }
}

/* transform={["scale(.45)", "scale(.8)"]} */