@font-face {
  font-family: "poster";
  src: url("./assets/fonts/Posterman_PERSONAL_USE_ONLY.otf");
}

.App {
  font-family: 'Open Sans', sans-serif;
  background-color: #000000;
  overflow-x: hidden;
  overflow-y: hidden;
}

.poster {
  font-family: "poster";
}